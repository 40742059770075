// Gatsby & React
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom
import Page from "../Layout/Page";
import Story from "../Content/Story";


// Content & Styles
import "../../assets/scss/App.scss";


const ArticlePage = ((props) => {
    return (
        <Page className={`${props.slug} ${props.className} pt-5 article-page`}>

            {props.before}

            <Container className={'justify-content-center mt-4 mb-4 pl-4 pr-4'}>
                <Row className={'justify-content-lg-center'}>
                    <Col lg={'9'}>
                        <Story slug={props.slug} alignTitle={props.alignTitle} />
                    </Col>
                </Row>
            </Container>

            {props.children}

        </Page>
    )
})

export default ArticlePage;
