import React from "react"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PillButton from "../components/PillButton";
import ArticlePage from "../components/Content/ArticlePage"

import "../assets/scss/App.scss"


const ExtendedLicensePage = ((props) => {
    return (
        <React.Fragment>
            <ArticlePage slug={'extended-license'}>
                <Container>
                    <Row fluid className={'justify-content-center mt-md-4 mb-4'}>
                        <PillButton
                            link={'/standard-license'}
                            variant={'primary'}
                            text={'Read the Standard License'}
                        />
                    </Row>
                </Container>
            </ArticlePage>
        </React.Fragment>
    )
})

export default ExtendedLicensePage;
